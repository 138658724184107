<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.first_name"
                outlined
                :label="$t('first_name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.last_name"
                outlined
                :label="$t('last_name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.username"
                outlined
                :label="$t('user_name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.email"
                outlined
                :label="$t('email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.address"
                outlined
                :label="$t('address')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.phone_number"
                outlined
                :label="$t('phone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.country_id"
                outlined
                :label="$t('country')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.password"
                outlined
                :label="$t('password')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="confirmedPassword"
                outlined
                :label="$t('confirm_password')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn :loading="loader" color="blue darken-1" text @click="submit">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "dialogData", "type", "loader"],
  data() {
    return {
      myDialog: false,
      data: {
        first_name: null,
        last_name: null,
        password: null,
        username: null,
        email: null,
        phone_number: null,
        address: null,
        country_id: null,
      },
      confirmedPassword: null,
    };
  },
  methods: {
    submit() {
      if (this.type == "edit") {
        for (let key in this.data) {
          if (this.data[key] === this.dialogData[key]) {
            delete this.data[key];
          }
        }
        this.$emit("editData", { data: this.data, id: this.dialogData.id });
      } else {
        this.$emit("createData", this.data);
      }
    },
  },
  computed: {},
  mounted() {
    if (this.type == "edit") {
      this.data = { ...this.dialogData };
    }
  },
};
</script>

<template>
  <div>
    <popup
      v-if="dialog"
      @closePopup="closePopup"
      @createData="create($event)"
      @editData="edit($event)"
      :dialog="dialog"
      :dialogData="dialogData"
      :type="popupType"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteCountry($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
    />
    <v-row class="mb-4">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="createAble"
          ><span style="color: white;">{{ $t("add") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="editAble"
      :deleteable="deleteItem"
      :loader="loader"
      :meta="pagination"
    />
  </div>
</template>
<script>
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import popup from "./create-edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import { Users } from "@/store/common/users/student";
export default {
  components: {
    dataTable,
    deletePopup,
    popup,
  },
  data() {
    return {
      Users,
      dialog: false,
      deleteDialog: false,
      dialogData: null,
      deleteDialogData: null,
      popupType: null,
    };
  },
  methods: {
    getData(page) {
      let params = {
        page: page,
      };
      Users.get(params);
      this.dialog = false;
    },
    createAble() {
      this.dialog = true;
      this.popupType = "create";
    },
    editAble(obj) {
      this.dialog = true;
      this.popupType = "edit";
      let item = {
        id: obj.id,
        first_name: obj.first_name,
        last_name: obj.last_name,
        phone_number: obj.phone_number,
        address: obj.address,
        username: obj.user.username,
        country_id: obj.country.id,
        email: obj.user.email,
      };
      this.dialogData = item;
    },
    deleteItem(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    closePopup() {
      this.dialog = false;
    },
    create(data) {
      Users.post(data);
      this.dialog = false;
    },
    edit(data) {
      Users.edit(data.data, data.id);
      this.dialog = false;
    },
    deleteCountry(id) {
      Users.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    tableData() {
      return Users.tableData;
    },
    loader() {
      return Users.getState.loading;
    },
    pagination() {
      return Users.tableData.meta;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
